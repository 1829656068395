.printing-position-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    border: 1px solid #cccccc50;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 5px;
    transition: box-shadow ease-in-out .3s;
    width: 20%;
    min-height: 350px;
    img{
        object-fit: contain;
    }
    .close-outline{
        display: block;
    }
    
    .clear-selection-section{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .clearselectionbtn{
        background-color: #0575B4 !important;
        color: #fff;
        display: flex;
        justify-content: center;
    }
    .clearselectionbtn:hover{
        background-color: #fff !important;  
        color: #0575B4 !important;
        border: 1px solid #0575B4 !important;
        backdrop-filter: blur(2px);
    }

}



.printing-position-card:hover{
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    cursor: pointer;
}

.printing-position-card-selected{
    background-color: #0575B410;
    border:1px solid #0575B4;
    transform: translateY(-20px);
}
.add-logo-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label{
        font-weight: 600;
        font-size: 14px;
    }

}
.addLogoBtn{
    color: #0575B4;
    border: 1px solid #0575B4;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &:hover{
        background-color: #0575B4 !important;
        color: #fff !important;
        border: 1px solid #fff !important;
    }
}


.make-my-product{
    width: 20%;
    display: flex;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0575B4 !important;
    color: #fff !important;
    border: 1px solid #0575B4 !important;
}

.upload-logo-step{
    display: flex;
    flex-direction: column;
    gap: 20px;
    img{
        object-fit: contain;
    }
}

.upload-section{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    .ant-upload-wrapper.ant-upload-picture-circle-wrapper{
        width: auto;
    }
    .removingBgBackground{
        background-color: #0575B4 !important;
        color: #fff !important;
    }

    .removingBgBackground:hover{
        color: #0575B4!important;   
        background-color: #fff!important;   
        border: 1px solid #0575B4 !important;
    }
}

.height-width {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 10px
}

.personalize-view {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 50px;
    gap: 20px;
    width: 100%;
    justify-content: center;
}

.printing-technique {
    text-align: center;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.image-section{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position : relative;

    .edit-image,
        .save-image {
            display: flex;
            align-items: center;
        }

    // img{
    //     object-fit: contain;
    //     position: absolute;
    //     top: 5px;
    //     right: 50px;
    //     z-index: 10;
    // }

    img:hover{
        cursor: pointer;
    }

  }

  .head-label{
    font-size: 14px;
    font-weight: 600;
  }

  .steps-to-order{
    display: flex;
    flex-direction: column;
    gap: 2px;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    width: 90%;
    margin : 0 auto;

    h5{
        font-weight: 600;
    }
    span{
        font-size: 13px;
    }
  }

  @media (max-width : 600px){
    .printing-position-card {
        width: 100% !important;
    }
    .make-my-product{
        width: 60%;
    }
    .personalize-view{
        display: flex;
        flex-direction: column;
    }
    .height-width{
        width: 100% !important;
        align-items: center;
    }
    .printing-technique{
        width: 100% !important;
        align-items: center;
    }
  }


.my-orders-page{
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 80vh !important;
    margin: 0 auto;
    width: 95%;
    height: auto;
    h4{
        font-size: 2vw;
    }
    .prod-name{
        font-size: 14px;
        font-weight: 600;
    }
    .prod-mc{
        font-size: 12px;
        font-weight: 400;
    }
    .prod-sd{
        font-size: 12px;
        font-weight: 400;
    }
    .no-orders-found{
        margin: 0 auto;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 40px;
        height: 70vh;
        flex-direction: column;
        gap: 20px;

        .continue-shop-btn{
            border-radius: 50px;
            height: 40px;
            display: flex;
            align-items: center;
            background-color: #F6313D90;
            color: #fff;
            gap: 10px;
            justify-content: center;
            cursor: pointer;
        }

        .continue-shop-btn:hover{
            background-color: #F6313D;
            color: #fff;
            border: 1px solid #F6313D;
            transform: translateX(10px);
        }

    }
}


@media (max-width : 550px){
    .my-orders-page .no-orders-found{
        height: 70vh;
        padding : 0px !important;
        margin-right: auto !important;
        margin-left: auto !important;
        span{
            text-align: center;
        }
    }
}

.downloadEstimate{
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.order-title{
    display: flex;
    justify-content: space-between;
}

@media (max-width : 600px){
    .order-title{
        flex-direction: column !important;
        justify-content: flex-start;
        gap: 2px;
    }
    .table-product{
        overflow-x: scroll;
    }
}
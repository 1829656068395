@import '../../styles/variable';

.footer-section {
    position: relative;
    top: 150px;
    width: 100%;

    .genie-arabia-footer {
        background-color: $footer-color;
        box-sizing: border-box;
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .cpy-rights{
            border-top: 1px solid #cccccc;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
        }

        .genie-arabia-avanexa{
            width: 80%;
            box-sizing: border-box;
            padding: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .rights-reserved{
                color: $secondary-color;
                font-size: 13px;
            }

            .service-vendor{
                display: flex;
                flex-direction: column;
                align-items: center;
                .services{
                    font-size: 8px;
                }
                .vendor{
                    font-size: 14px;
                }
            }

        }

        .logo-and-quick-links {
            width: 50%;
            box-sizing: border-box;
            padding: 50px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;

            .social-links{
                width: 50% !important;
            }

            ol {
                display: flex;
                width: 35%;
                gap: 10px;
                li {
                    list-style-type: none;
                    font-size: clamp(12px, 8vw, 13px);
                }

                li:hover {
                    cursor: pointer;
                }
            }

            ul {
                display: flex;
                width: 100%;
                justify-content: space-evenly;
                padding-left : 0rem;

                
                .footer-link{
                    cursor: pointer;
                    text-decoration: none !important;
                    color: #000;
                    transition: transform ease-in .2s;
                    font-size: 12px;
                }

                .footer-link:hover{
                    transform: scale(1.25px);
                }

                li {
                    list-style-type: none;
                    font-size: clamp(12px, 8vw, 13px);
                    transition: transform ease-in .2s;
                }

                li:hover {
                    cursor: pointer;
                    transform: scale(1.2);
                }
            }

        }
    }

    
}


@media (max-width : 425px){
    .footer-section .newsletter-main .newsletter-main-inner-sec{
        width: 95% !important;
        flex-direction: column !important;
        gap: 30px !important;
    }

    .footer-section .genie-arabia-footer .logo-and-quick-links{
        padding: 0px !important;
        padding-top: 20px !important;
    }

    .footer-section .newsletter-main .newsletter-main-inner-sec .newsletter-genie-arabia-l,
    .newsletter-genie-arabia-r{
        width: 100% !important;
    }
    .footer-section .genie-arabia-footer .logo-and-quick-links{
        width: 100%;
    }
    .footer-section .genie-arabia-footer .logo-and-quick-links ol{
        width: auto !important;
        padding-left : 0rem;
    }
    .footer-section .genie-arabia-footer .logo-and-quick-links ul{
        gap: 10px;
        padding-left: 0rem;
    }

    .footer-section .genie-arabia-footer .genie-arabia-avanexa{
        flex-direction: column;
        gap: 20px;
    }
    .footer-section .newsletter-main .newsletter-main-inner-sec .newsletter-genie-arabia-l h3{
        font-size: 22px !important;
    }
    .footer-section .newsletter-main .newsletter-main-inner-sec .newsletter-genie-arabia-l span{
        font-size: 12px !important;
        line-height: 25px !important;
    }
}


@media (max-width : 850px){
    .newsletter-main-inner-sec{
        flex-direction: column;
        width: 95% !important;
        gap: 20px;
    }
    
    .footer-section .newsletter-main .newsletter-main-inner-sec .newsletter-genie-arabia-l,
    .newsletter-genie-arabia-r{
        width: 100% !important;
    }

  

    .footer-section .genie-arabia-footer .logo-and-quick-links{
        width: 100%;
    }
}




.our-webshop-area{
    box-sizing: border-box;
    padding: 40px;
    width: 80%;

    h3{
        margin-bottom: 20px;
    }

    h4{
        font-weight: 600;
        font-size: 24px;
    }
    
    h5{
        font-size: 14px;
        font-size: 400;
    }

    .webshop-card{
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #cccccc50;
        display: flex;
        flex-direction: column;
        width: fit-content;
        gap: 10px;
        box-sizing: border-box;
        padding: 10px;
        align-items: center;
        justify-content: center;
    }

}

.newsletter-main {
    background-color: #F6323E;
    color: $white;
    box-sizing: border-box;
    padding: 40px;
    display: flex;
    justify-content: center;

    .newsletter-main-inner-sec {
        width: 80%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        .newsletter-genie-arabia-l {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;
            width: 50%;

            h3 {
                font-size: clamp(14px, 12vw, 32px);
                color: #fff;
            }

            span {
                font-size: clamp(10px, 7vw, 14px);
                line-height: 30px;
            }
        }

        .newsletter-genie-arabia-r {
            width: 40%;

            .ant-input {
                border-radius: 0px !important;
                width: 100%;
                height: 50px;
                border-right: none;
            }

            .ant-input:hover{
                border: 1px solid transparent;
            }

            .ant-input:focus {
                border: 1px solid transparent;
            }

            .ant-input-group-addon {
                background-color: $white;
                border: 1px solid transparent !important;
                border-radius: 0px !important;
            }

            .subscribebtn {
                border-radius: 0px !important;
                background-color: $black;
                color: $white;
                border: 1px solid transparent !important;
                height: 40px;
            }

            .subscribebtn:hover{
                border: 1px solid $black !important;
                color: $black !important;
                background-color: $white !important;
            }
        }

    }

}

@media (max-width : 600px){
    .newsletter-main .newsletter-main-inner-sec .newsletter-genie-arabia-l{
        width: 100%;
    }
}
.mobilemenu{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 70%;
    height: 100vh;
    background-color: transparent;
    backdrop-filter: blur(15px);
    z-index: 999;
    box-sizing: border-box;
    padding: 10px;
    
    .close{
        margin-left: auto;
    }

    div{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
    }
}
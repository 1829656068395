.ant-select-selector {
  width: 300px !important;
  border: 0 !important;
  border-radius: 0 !important;
  border: 1px solid #cccccc;
}
.search-product-container {
  display: flex;
  gap: 10px;
  p {
    margin: 0;
  }
}
.ant-tabs-nav-list {
  line-height: 10px;
}
.search-cont {
  .select-search {
    border: 1px solid transparent;
    .ant-select-selector {
      border: 1px solid #cccccc !important;
    }
  }
}

.ant-drawer-body {
  padding: 2px !important;
}

.advance-search-area{
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .advance-search-item{
        display: flex;
        flex-direction: column;
        gap: 2px;

        .input-area{
            margin-left: 10px;
        }
    }

    .ant-input-number-input{
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-select-multiple{
        border: 1px solid #cccccc;
    }

}

.advance-search{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: auto;
    border-radius: 50px;

    &:hover{
        border: 1px solid !important;
        color: #000000  !important;

    }
}


.color-area{
    display: flex;
    gap: 4px;
    cursor: pointer;

    .color-circle {
        height: 25px;
        width: 25px;
        background: color;
        border: 1px solid #cccccc;
        border-radius: 50px;
        position: relative;
    }

    .check-outline{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        font-weight: 600;
    }

}
/*------ 22. contact Page  ------*/

.contact-map {
  position: relative;

  height: 560px;

  @media #{$xs-layout} {
    height: 400px;
  }
}

.contact-info-wrap {
  padding: 2rem 2rem 2rem 2rem;
  height: 100%;
  display: flex;
  // gap: 30px;
  flex-direction: column;
  justify-content: center;
  // border: 1px solid red;
  background-color: #f3f3f3;


  @media #{$lg-layout} {
    padding: 50px 0px 50px 0px;
  }

  @media #{$md-layout} {
    padding: 50px 0px 8px 0px;
    // background-color: rgb(100, 19, 62);
  }

  @media #{$sm-layout} {
    padding: 2rem 5rem 0.5rem 1rem;
    // background-color: rgb(250, 42, 153);
    // gap: 30px;
  }

  @media #{$xs-layout} {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 50px 20px 45px 50px;
    // background-color: cornflowerblue;
  }

  .single-contact-info {
    display: flex;
    align-items: center;
    justify-content: start;
    // width: 290px;
    // border: 1px solid rgb(80, 56, 56);
    // border-radius: 10px;
    padding: 1rem;

    @media #{$lg-layout} {
      // border: 1px solid green;
      width: 100%;
    }

    @media #{$md-layout} {
      // border: 1px solid yellow;
      width: 100%;
      // margin-bottom: 2rem;
    }

    @media #{$sm-layout} {
     
      width: 100%;
    }


    @media #{$xs-layout} {
      // border: 1px solid rgb(18, 32, 229);
      width: 100%;
    }

    .contact-icon {
      margin-right: 20px;

      i {
        font-size: 20px;
        line-height: 40px;

        display: inline-block;

        width: 40px;
        height: 40px;

        transition: all 0.3s ease 0s;
        text-align: center;

        color: #252525;
        border: 1px solid #252525;
        border-radius: 100%;
      }
    }

    .contact-info-dec {
      p {
        line-height: 1;

        margin: 0 0 9px;

        color: #404040;

        a {
          color: #404040;

          &:hover {
            color: $theme-color;
          }
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    &:hover .contact-icon i {
      color: #fff;
      background-color: #252525;
    }
  }
}

.contact-info-wrap>:nth-child(4) {
  // align-self: center;
  padding: 25px 25px 25px 50px;
}

.contact-social {
  margin-top: 58px;

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;

    margin: 0 0 17px;

    color: #4d4d4d;
  }

  ul {
    li {
      display: inline-block;

      margin: 0 10px;

      a {
        font-size: 16px;

        color: #4d4d4d;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}

.contact-form {
  padding: 50px 110px 50px 110px;

  background-color: #f3f3f3;

  @media #{$lg-layout} {
    padding: 50px 50px 48px 50px;
    // border: 1px solid red;
  }


  @media #{$xl-layout} {
    padding: 45px 50px 10px 50px;
    // border: 1px solid rgb(47, 252, 15);
  }

  @media #{$md-layout} {
    padding: 32px 30px 32px 30px;
  }

  @media #{$xs-layout} {
    padding: 50px 30px 50px 30px;
  }

  @media screen and (max-width:1999px) {
    // border: 2px solid greenyellow;
    padding-bottom: 23px;
  }

  .contact-title {
    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 1;

      margin-bottom: 36px;

      color: #464646;
    }
  }

  .contact-form-style {

    input,
    textarea {
      height: 50px;
      margin-bottom: 30px;
      padding: 2px 14px;

      color: #000;
      border: 1px solid #c1c1c1;
      background: transparent;
    }

    textarea {
      height: 314px;
      margin-bottom: 0;
      padding: 20px 14px;

      @media #{$md-layout} {
        // border: 1px solid yellow;
        height: 100px;
      }

      @media #{$lg-layout} {
        // border: 1px solid red;
        height: 140px;
      }

      @media screen and (max-width:1999px) {
        // border: 2px solid greenyellow;
        height: 149px;
      }

      @media #{$xl-layout} {
        // border: 1px solid rgb(62, 10, 235);
        height: 10rem;
      }





    }

    button {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;

      margin-top: 38px;
      padding: 15px 52px;

      text-transform: uppercase;

      color: #fff;
      border: none;
      background-color: #404040;

      &:hover {
        background-color: $theme-color;
      }
    }
  }

  p {
    color: #333;

    &.success {
      margin-top: 10px;
    }
  }
}

.contact-form-style {
  .row {
    & div[class^="col-"] {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.contact-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

.whyimportant h1 , 
.whyimportant p{
  color: #fff;
}





.whyimportant:hover h1,
.whyimportant:hover p{
  color: #000;
}

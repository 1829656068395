.terms-and-condition-genie-arabia{
    min-height: 80vh;
    box-sizing: border-box;
    padding: 40px;
    padding-top: 10px;

    h4{
        text-align: center;
        text-decoration: underline;
    }
   
    .static-content-area{
        box-sizing: border-box;
        padding: 20px;
        
        h1{
            color: #333333;
            font-size: 16px;
            text-decoration: underline;
        }

        ul{
            display: flex;
            flex-direction: column;
            gap: 5px;

            h5{
                color: #333;
                font-weight: 600;
            }

        }

        ul li{
            font-size: 14px;
            color: #383838;
        }
    }
}
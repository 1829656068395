$black : #000;
$pink-shade : #FF466F;
$secondary-color : #666;
$white : #fff;
$gold-shade : #FFAE00;
$white-shade : #fff;
$temp-pm-color : #FEF6F8;
$footer-color : #FCEAEC;
$genie-color-1 : #0575B4;
$genie-color-2 : #F6313D;


$lg-font-size : clamp(14px , 12vw , 16px);
$md-font-size : clamp(12px , 8vw , 14px);
$xlg-font-size : clamp(12px , 15vw , 26px);